import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'
const Footer2  = () => {
  return (
    <div>
     <div>

        <div className='footer-options'>
          
            <span className='footer-span-1'>
            © 2023 - Tuff Trainer

            </span>
       
            <div className='footer-div2'>
            <Link
            to={'/Privacy_Policy'}
            >
            <span className='footer-span-2'>
            Privacy Policy
            </span>
            </Link>
            <Link to={'/Terms_Condition'}>
            <span className='footer-span-3'>
            Terms & Conditions
            </span>
            </Link>
            </div>
        </div>
     </div>
    </div>
  )
}

export default Footer2 