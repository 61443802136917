import { collection,  onSnapshot, orderBy, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { db } from '../../Firebase/Firebase';
import "./Blog.css";
import Streadata from '../../Features/Streadata/Streadata'
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import Footer from '../Footer/Footer ';
const Blog = () => {

  const [Blogs, setBlogs] = useState();

  useEffect(() => {
    const todosref = collection(db, "Blogs");
    const q = query(todosref , orderBy("createdAt" , "asc"));
    const unsub = onSnapshot(q, (querySnapshot) => {
      let Blog = [];
      querySnapshot.forEach((doc) => {
        Blog.push({
          id: doc.id,
          ...doc.data(),
        });
        setBlogs(Blog);
       
      });
    });

    return () => unsub();
  }, []);





  return (
    <div>

      <div className=' bg-blog'>
    <div className="body-fix-h 
   ">
    
      <div className="live-s-f wrapper">
        <div>
          <Sidebar1 />
        </div>
<div className='grid-c1 main-y'>

        <div className="live-head-1  grid-c2">
          <div className="live-head-2">
            <div className="live-head-3">
              <div className="blog-head-h">
                <h1>Vlog</h1>
              </div>
              <div className="blog-data-b">
              {Blogs?.map((Bloging) => (
 
      <Streadata Blogs={Bloging} />
  
  ))}

              </div>
            </div>
          </div>

        </div>
       <div className="grid-c3">
              <Footer />
            </div>
</div>

      </div>
    </div>
    </div>
    
    </div>
  );
};

export default Blog;
