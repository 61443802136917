import React from "react";
import './CaseStudy.css'
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import Footer from "../Footer/Footer ";
import { useNavigate } from "react-router-dom";
import Image1 from './7618255.jpg'


const CaseStudy = () => {

const navigate  = useNavigate()



  return (
    <div className="case-c">
    <div className="body-fix-h">
<div className="wrapper ">
<div className="sidebar-home-d">
<Sidebar1/>
</div>
<div className="grid-c1 main-y">
<div className="section1-im1 grid-c2  ">
<div className="head-pp ">
<div className="contact-section2">
<div style={{textAlign:'center'}}>
</div>
</div>

<div className="contact-us-form">
  <div className="CaseStudyTitle">
  <h1 className="head-case">Case Study</h1>


  </div>



<div style={{marginTop: "30px"}} class="l-container">
<div onClick={()=> navigate(`/CaseStudyDetails/${'Ron'}`)} class="card">
        <div class="imgBx">
            <img src={Image1} alt="images" />
        </div>
        <div class="details">
            <h2>RON SINGH</h2>
        </div>
      </div>
</div>
</div>
</div>
</div>
<div className="grid-c3">
              <Footer />
            </div>
</div>
</div>
</div>
</div>
    


  );
};

export default CaseStudy;
