import React, { useEffect, useMemo, useState } from "react";
import "./Forums.css";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import { FaUserCircle } from "react-icons/fa";
import { BsFillReplyFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { AiOutlineSend } from "react-icons/ai";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-hot-toast";
import Footer from "../Footer/Footer ";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  increment,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db, storage } from "../../Firebase/Firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

import { MdCancel } from "react-icons/md";
import moment from "moment";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
const Forums = () => {
  const Moment = require("moment");

  const fileTypes = ["JPEG", "PNG"];
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("");
  const [forum, setForum] = useState({});

  const { user } = useContext(AuthContext);

  const [getUser, setGetUser] = useState("");

  useEffect(() => {
    getDoc(doc(db, "usersLesson", user?.uid)).then((docSnap) => {
      if (docSnap.exists()) {
        setGetUser(docSnap.data());
      } else {
        console.log("No Document Found");
      }
    });
  }, [user?.uid]);

  useEffect(() => {
    const q = query(
      collection(db, "Forum"),
      where("verified", "==", "approved")
    );
    const unsub = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setForum(
        cities.sort((a, b) => new Moment(b.createdAt) - new Moment(a.createdAt))
        // cities.sort((a, b) =>
        // a.createdAt.seconds- b.createdAt.seconds
        //   // a.createdAt.seconds < b.createdAt.seconds ? -1 : 1
        // )
      );
    });

    return () => unsub();
  }, []);

  const [search, setSearch] = useState("");

  const countries = useMemo(() => {
    if (!search) return forum;

    return forum.filter((item) => {
      return item.name.toLowerCase().includes(search.toLowerCase());
    });
  }, [search, forum]);

  const addDiscussion = async () => {
    if (file) {
      const uploadImg = async () => {
        const imgRef = ref(
          storage,
          `forums/${new Date().getTime()} - ${file.name}`
        );
        try {
          const snap = await uploadBytes(imgRef, file);
          const url = await getDownloadURL(ref(storage, snap.ref.fullPath));

          await addDoc(collection(db, "Forum"), {
            Image: url,
            name: name,
            description,
            tags,
            user: getUser?.name,
            email: getUser?.email,
            verified: "pending",
            createdAt: moment().format(),
            replies: 0,
            lastreply: "0",
            userId: user.uid,
          });
          addDoc(collection(db, "usersLesson", getUser.uid, "Notification"), {
            Notification: `Your Forum's Approval is Pending`,
            markasRead: false,
          });
          updateDoc(doc(db, "usersLesson", getUser.uid), {
            NotificationNumber: increment(1),
          })
            .then(() => {
              toast("Forum will posted after approval", {
                duration: 2000,
                position: "top-center",
                // icon: "✔",
                style: {
                  borderRadius: "10px",
                  background: "#3499EE",
                  color: "#fff",
                },
              });
              toast("Forum Added", {
                duration: 2000,
                position: "top-center",
                icon: "✔",
                style: {
                  borderRadius: "10px",
                  background: "#3499EE",
                  color: "#fff",
                },
              });
              closeModal();
            })
            .then(() => {
              setName("");
              setDescription("");
              setFile();
              setTags("");
            });
        } catch (err) {
          console.log(err);
        }
      };
      uploadImg();
    } else {
      await addDoc(collection(db, "Forum"), {
        name: name,
        description,
        tags,
        user: getUser?.name,
        email: getUser?.email,
        verified: "pending",
        createdAt: moment().format(),
        replies: 0,
        lastreply: "0",
        userId: user.uid,
      });
      addDoc(collection(db, "usersLesson", getUser.uid, "Notification"), {
        Notification: `Your Forum's Approval is Pending`,
        markasRead: false,
      });
      updateDoc(doc(db, "usersLesson", getUser.uid), {
        NotificationNumber: increment(1),
      })
        .then(() => {
          toast("Forum will posted after approval", {
            duration: 2000,
            position: "top-center",
            // icon: "✔",
            style: {
              borderRadius: "10px",
              background: "#3499EE",
              color: "#fff",
            },
          });
          toast("Forum Added", {
            duration: 2000,
            position: "top-center",
            icon: "✔",
            style: {
              borderRadius: "10px",
              background: "#3499EE",
              color: "#fff",
            },
          });
          closeModal();
        })
        .then(() => {
          setName("");
          setDescription("");
          setFile();
          setTags("");
        });
    }
  };

  return (
    <div>
      <div className="bg-formus">
        <div className="body-fix-h">
          <div className="wrapper ">
            <div className="flex-sid-forms">
              <div className="sidebar-home-d">
                <Sidebar1 />
              </div>
              <div className="main-y grid-c1">
                <div className="grid-c2">
                  <div className="forums-data1  ">
                    <div className="forums-data ">
                      <form>
                        <input
                          placeholder="Search"
                          type="search"
                          className="search-input-forums"
                          name="search"
                          id="search"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </form>

                      <div className="border-jh">
                        <div className="sub-sc">
                          <h1 className="all-h1">All Discussions</h1>
                          <div className="btn-ss">
                            <button
                              onClick={() => navigate("/User")}
                              className="New-discussion-btn1"
                            >
                              Your discussion{" "}
                            </button>
                            <button
                              onClick={openModal}
                              className="New-discussion-btn1"
                            >
                              New discussion{" "}
                            </button>
                          </div>
                        </div>
                        <hr />

                        <div className="main-comments-div">
                          <div>
                            {Array.isArray(forum)
                              ? countries.map((item, key) => {
                                  return (
                                    <div>
                                      <div
                                        onClick={() =>
                                          navigate(`/Reply/${item.id}`)
                                        }
                                        className="comment-1"
                                      >
                                        <div className="comm-gf">
                                          <FaUserCircle className="svg-user" />

                                          <div className="updates-time-rep">
                                            <h1 className="h-h1">
                                              {item.name}
                                            </h1>
                                            <div className="span-updates">
                                              <span className="hov-span1">
                                                <span>
                                                  <BsFillReplyFill className="svg-fill" />
                                                </span>
                                                <span>{item.user}</span>
                                              </span>
                                              <span></span>
                                              <span className="hov-span1">
                                                {moment(
                                                  item.createdAt
                                                ).fromNow()}
                                              </span>

                                              <span>
                                                · {item.replies} Replies
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <hr />
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="grid-c3">
                  <Footer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
        >
          <div className="header-modal-s">
            <div className="search-input">
              <input
                placeholder="Discussion Title"
                type="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                id="name"
                required
              />
            </div>
          </div>

          <hr />
          {/* textarea-modal */}
          <div>
            <div className="modal-text-area-data-1">
              <div className="text-area-modal-s">
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="text-modal-area1"
                  placeholder="Type your discussion content here"
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  required
                ></textarea>
              </div>
              <div className="upload-options">
                {/* <input type="file" name="" id="" /> */}
                <FileUploader
                  classes="drop_area"
                  multiple={false}
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                />
              </div>
            </div>
            <hr />
            <div className="section-1-text">
              <textarea
                value={tags}
                onChange={(e) => setTags(e.target.value)}
                placeholder="Type one or more tag, comma separated "
                className="text-area-modal-2"
                name=""
                id=""
                cols="30"
                rows="10"
              ></textarea>
            </div>
            <hr />
            <div className="check-box-post-btn">
              <div className="check-box-post-btn1">
                <button
                  disabled={!name || !description}
                  onClick={() => addDiscussion()}
                  className="post-btn-dis"
                >
                  Send <AiOutlineSend />
                </button>
                <button onClick={closeModal} className="post-btn-dis">
                  Cancel <MdCancel />
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    </div>
  );
};

export default Forums;
