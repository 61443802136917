import { getDoc , doc} from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import {  db } from "../../Firebase/Firebase";
import Footer from "../Footer/Footer ";
import "./Home.css";

const Home = () => {


  const { user } = useContext(AuthContext);



  const[getUser,setGetUser] = useState()

useEffect(()=> {

  getDoc(doc(db, 'usersLesson' , user?.uid )).then((docSnap)=> {
    if(docSnap.exists()) {
      setGetUser(docSnap.data())
    }
  })

},[user])




  const navigate = useNavigate();

  return (
    <div className="bg-hh">
    <div className="body-fix-h">
    <div className="wrapper ">

  <div className="home-side-bar-data">
    <div className="sidebar-home-d">
      <Sidebar1 />
    </div>
    <div className="main-y grid-c1 ">
<div className="grid-c2">

    <div className="data-div-h ">
      <div className="data-container">
        {/* <div className="update-1">
          <h1>
            Female Module now live, CLICK HERE to find out more and
            purchase.
          </h1>
        </div> */}
        <div className="update-1">
          <h1>
            Live stream will be announced shortly.
          </h1>
        </div>
        <div className="h1-courses">
          <h1>COURSES</h1>
        </div>

        <div className="card-head-course">
          <div className="card-head-data-j">
            <div>
              <img
                className="ww"
                src={require("../../Img/Artboard2.jpg")}
                alt=""
                onClick={() => navigate("/Modules")}
              />
            </div>
            <div className="card-h-certifi">
              <h1>Contest Prep Certification</h1>
            </div>
            <div className="bodd-d">
            <div class="progress">
                        {getUser?.Module1 === 25 ? (
<div class="progress-value1"></div>
                        ) : (
                          <>
                          {getUser?.Module1 === 50 ? (
                            <div class="progress-value2"></div>
                                                      ) : (
                                                        <>
                                                        {getUser?.Module1 === 75 ? (
                                                          <div class="progress-value3"></div>
                                                                                    ) : (
                                                                                      <>
                                                                                
                                                                                       {getUser?.Module1 === 100 ? (
                                                          <div class="progress-value4"></div>
                                                                                    ) : (
                                                                                      <>
                                                                                
                                                                                        <div class="progress-value"></div>
                                                                                                              
                                                                                      </>
                                                                                    )}
                                                                                                              
                                                                                      </>
                                                                                    )}
                                                        </>
                                                      )}
                          </>

                        )}

</div>
            </div>
            <div>
              <h1 className="percentage-course-com">{getUser?.Module1}% Complete</h1>
            </div>
            <div>
              <button
                onClick={() => navigate("/Modules")}
                className="view-btn-hom"
              >
                View Lessons{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>

    <div className="grid-c3">
          <Footer />
        </div>
    </div>
  </div>
</div>
</div>
</div>

  );
};

export default Home;
