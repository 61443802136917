import { onSnapshot, orderBy } from 'firebase/firestore'
import React from 'react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import './caseStudyDate.css'
import { collection, query, getDocs } from "firebase/firestore";
import { db } from '../../../Firebase/Firebase'
import { useState } from 'react'

const CaseStudyDate = () => {


const {id} = useParams()
const navigate  = useNavigate()


const [getdata, setGetData] = useState()



useEffect(() => {
    const q = query(collection(db, `CaseStudy/${id}/${id}`));
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
          cities.push({ id: doc.id, ...doc.data() });
        });
        setGetData(cities);
        console.log(cities)
      }
    );

    return () => {
      unsubscribe();
    };
  },
  [id]);




  return (
  <div className="caseStudyDate">

  </div>
  )
}

export default CaseStudyDate